@font-face {
  font-family: "Helvetica Neue";
  src: url("./fonts/HelveticaNeueMedium.otf") format("opentype");
}
@font-face {
  font-family: "Tiempos Headline";
  src: url("./fonts/TiemposHeadline-Regular.otf") format("opentype");
}

html {
  -webkit-font-smoothing: auto;
}

h1 {
  font-size: 4rem;
  line-height: 4.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.125rem;
}

h4 {
  font-size: 1rem;
}

h5,
h6,
p {
  font-size: 0.875rem;
}

/* Media Query for Tablet */
@media screen and (max-width: 700px) {

  h1 {
    font-size: 3rem;
    line-height: 3.25rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5,
  h6,
  p {
    font-size: 0.875rem;
  }
}


.t-font {
  font-family: "Tiempos Headline";
  font-weight: 600;
}
.h-font {
  font-family: "Helvetica Neue";
  font-weight: 500;
  color: #141413;
}

/* Paragraph Styles */
.p-large {
  font-size: 1.25rem;
}

.p-medium {
  font-size: 1rem;
}

.p-small {
  font-size: 0.875rem;
}

.p-extra-small {
  font-size: 0.75rem;
}

.txt-light {
  font-weight: 400;
}

.txt-regular {
  font-weight: 500;
}

.txt-semiBold {
  font-weight: 600;
}

.txt-bold {
  font-weight: 900;
}

.txt-capitalize {
  text-transform: capitalize;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-lowercase {
  text-transform: lowercase;
}

.txt-italic {
  font-style: italic;
}

.txt-underline {
  text-decoration: underline;
}

.txt-decoration-none {
  text-decoration: none;
}

.txt-primary-color {
  color: var(--primary-color);
}

.bg-primary-color {
  color: var(--primary-color);
}

.display-flex {
  display: flex;
}

.flex-equal {
  flex: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-center {
  align-items: center;
}
.flex-align-stretch {
  align-items: stretch;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-stretch {
  justify-content: stretch;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.column-gap-5 {
  column-gap: 5px;
}

.column-gap-10 {
  column-gap: 10px;
}

.column-gap-20 {
  column-gap: 20px;
}

.column-gap-30 {
  column-gap: 30px;
}

.column-gap-40 {
  column-gap: 40px;
}

.column-gap-50 {
  column-gap: 50px;
}

.row-gap-10 {
  row-gap: 10px;
}

.row-gap-20 {
  row-gap: 20px;
}

.row-gap-30 {
  row-gap: 30px;
}

.row-gap-40 {
  row-gap: 40px;
}

.row-gap-50 {
  row-gap: 50px;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.m-0 {
  margin: 0px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.m-50 {
  margin: 50px;
}

.m-60 {
  margin: 60px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 50px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 80px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-0 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.p-60 {
  padding: 60px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-1 {
  opacity: 1;
}

/*** hidden classes ***/

.d-block {
  display: block;
}

.d-none {
  display: none;
}


/* Update by PK */

.column-gap-2 {
  column-gap: 2px;
}

.column-gap-4 {
  column-gap: 4px;
}

.column-gap-8 {
  column-gap: 8px;
}

.column-gap-12 {
  column-gap: 12px;
}

.column-gap-14 {
  column-gap: 14px;
}

.column-gap-16 {
  column-gap: 16px;
}

.column-gap-20 {
  column-gap: 20px;
}

.row-gap-2 {
  row-gap: 2px;
}

.row-gap-4 {
  row-gap: 4px;
}

.row-gap-8 {
  row-gap: 8px;
}

.row-gap-12 {
  row-gap: 12px;
}

.row-gap-14 {
  row-gap: 14px;
}

.row-gap-16 {
  row-gap: 16px;
}

.row-gap-20 {
  row-gap: 20px;
}

.pm-0 {
  padding: 0px;
  margin: 0px;
}

.m-0 {
  margin: 0px;
}

.p-2 {
  padding: 2px;
}

.p-4 {
  padding: 4px;
}

.p-8 {
  padding: 8px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.p-24 {
  padding: 24px;
}

.p-16-24 {
  padding: 16px 24px;
}

.m-2 {
  margin: 2px;
}

.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px;
}

.m-12 {
  margin: 12px;
}

.m-16 {
  margin: 16px;
}

.m-24 {
  margin: 24px;
}

.m-16-24 {
  margin: 16px 24px;
}

.c-white {
  color: #fff;
}
.bg-white {
  background-color: #fff;
}

.c-primary {
  color: #5e526a;
}
.bg-primary {
  background-color: #5e526a;
}

.c-orange {
  color: #ff9800;
}
.bg-orange {
  background-color: #ff9800;
}

.c-red {
  color: #f44336;
}
.bg-red {
  background-color: #f44336;
}

.c-green {
  color: #4caf50;
}
.bg-green {
  background-color: #4caf50;
}

.c-yellow {
  color: #ffeb3b;
}
.bg-yellow {
  background-color: #ffeb3b;
}

.c-blue {
  color: #03a9f4;
}
.bg-blue {
  background-color: #03a9f4;
}

.c-seashell {
  color: #f2f2f2;
}
.bg-seashell {
  background-color: #f2f2f2;
}

.c-charcoal {
  color: #222023;
}
.bg-charcoal {
  background-color: #222023;
}

.c-grey {
  color: #727160;
}
.bg-grey {
  background-color: #727160;
}

.p-10px {
  font-size: 10px;
}
.p-12px {
  font-size: 12px;
}

.heading {
  font-size: 0.75rem;
  opacity: 0.3;
  color: #222023;
  font-weight: 600;
}

.btn-txt {
  font-size: 0.75rem;
  font-weight: 500;
}

.auth-full-center-div {
  height: 100vh;
  width: 100%;
  /* background: #f2f2f2; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-modal-center-div {
  height: 550px;
  /* width: calc(550px * 1.5); */
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-form {
  width: 300px;
  background-color: #ffffff;
  padding: 12px;
}

.practitioner-card {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-radius: 8px;
  transition: all ease 0.2s;
}
.practitioner-card:hover {
  border: 1px solid #fff;
  background-color: #f2f2f2;
  transform: scale(1.03);
}

.new-search-bar {
  border: 1px solid #d9d9d9;

  background-color: #fff;
  border-radius: 16px;
  transition: all ease-in 0.2s;
  box-shadow: 5px 5px 6px lightgrey;
}

.new-search-bar:hover {
  border: 1px solid #ffffff;
  box-shadow: 10px 10px 30px lightgrey;
}

.new-search-bar.dashed-border {
  border: 1px dashed #5e526a;
  transition: all ease-in 0.2s;
}

.recent-card {
  /* border: 1px solid #d9d9d9; */
  background-color: transparent;
  cursor: pointer;
  border-radius: 8px;
  transition: all ease 0.2s;
}

.recent-card:hover {
  /* border: 1px solid #ffffff; */
  background-color: #ffffff;
  transform: scale(1.01);
  box-shadow: 1px 1px transparent;
}

.hover-it {

  background-color: transparent;
  cursor: pointer;
  border-radius: 8px;
  transition: all ease 0.2s;
}

.hover-it:hover {
  background-color: #ffffff;
  transform: scale(1.01);
  box-shadow: 1px 1px transparent;
}


.bg-f1 {
  background-color: #f1f1f1;
}
.bg-f2 {
  background-color: #f2f2f2;
}
.bg-f3 {
  background-color: #f3f3f3;
}
.bg-f4 {
  background-color: #f4f4f4;
}
.bg-f5 {
  background-color: #f5f5f5;
}
.bg-f6 {
  background-color: #f6f6f6;
}
.bg-f7 {
  background-color: #f7f7f7;
}
.bg-f8 {
  background-color: #f8f8f8;
}
.bg-f9 {
  background-color: #f9f9f9;
}

.text-reveal {
  font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 2em;
    margin-bottom: 2em;
    opacity: 0;
    transform: translateY(3em);
    animation: loadH1 var(--duration1) var(--easing) var(--delay1) forwards;
}

/* Update by PK */
